@import "../node_modules/antd/dist/antd.css";
.login-form {
  align-items: center;
  height: calc(100vh - 72.5px);
}

.ant-tag {
  padding: 0 10px;
  font-size: 15px;
  border-radius: 30px;
}
.restTag {
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 20px;
}
.site-page-header {
  display: flex;
  align-items: center;
  border: 1px solid rgb(235, 237, 240);
}
.oldRestNewRest .ant-page-header-content{
  width: 100%;
}
.ant-page-header-content{
  width: 50%;
  padding-top: 0px;
}
/* 
.navbar {
  margin-bottom: 3rem;
} */

.file-input {
  position: relative;
  opacity: 1;
}
.image-icon-box {
  position: relative;
  height: 100%;
  margin: 1rem;
  max-width: 120px;
  width: 100%;
}
.table td,
.table th {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 10px 2px;
  vertical-align: top;
}

@media screen and (max-width: 1366px) {
  .mainLoginPage .card-content,.modal-content .is-child {
    padding: 2.5rem 2.5rem !important;
}
  .report-box {
    width: 160px !important;
}
.menu-list a,.ant-calendar-picker-input.ant-input,.report-box p {
  font-size: 10px !important;
}

.menu-list2 a,.ant-calendar-picker-input.ant-input,.report-box p {
  font-size: 12px !important;
}
.ant-page-header-heading-sub-title,.ant-btn, button,table td:not([align]), table th:not([align]){
  font-size: 12px !important;
}
.ant-page-header-heading-title,#mainDataDisplay td, #mainDataDisplay th,.checkbox, .radio, label {
  font-size: 14px !important;
}
.navbar > .container {
  padding: 0px 25px;
}
.navbar-item img {
  max-height: 2rem !important;
  width: 100%;
}
#mainDataDisplay td, #mainDataDisplay th,.ant-table-thead > tr > th, .ant-table-tbody > tr > td,.ant-card-body  {
  padding: 10px !important;
}
}

@media screen and (min-width: 769px), print {
  .column.is-one-quarter,
  .column.is-one-quarter-tablet {
    flex: none;
    width: 20%;
    padding-right: 41px;
  }
}

.image-icon {
  height: 100%;
  padding: 5px;
  max-width: 120px;
  max-height: 120px;
  /* margin: 1rem; */
}

.delete {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-content {
  width: auto;
}

.modal-content .is-child {
  padding: 2em 6em;
  border-radius: 20px;
}
.navbar-item img {
  max-height: 4rem;
}

.column.is-one-fifth{
  width:100% !important;
 }
 .columns:not(.is-desktop){
   display:block !important;
   margin:0 auto !important;
 }
 .menu{
   display:flex;
 }
 .menu-list{
   display:flex;
 }
 .menu-list2{
  display:flex;
}

 .columns:not(.is-desktop) {
   display: block !important;
 }
 .column.is-one-fifth {
   width: 100% !important;
   display: flex !important;
   justify-content: center;
   margin: 0 auto !important;
 }
 .menu-list a{
   /* border:1px solid #DBDBDB; */
   font-size: 13px;
   height: 50px;
   box-shadow: 0px 0px 4px 1px #dbdbdb;
   display: flex;
   align-items: center;
 }
 .menu-list2 a{
  /* border:1px solid #DBDBDB; */
  font-size: 13px;
  height: 50px;
  box-shadow: 0px 0px 4px 1px #dbdbdb;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: gray;
}
 
 .menu-list li{
   padding:0 5px !important;
 } 
  
 .menu-list2 li{
  padding:0px 10px !important;
  margin-top: 40px !important;
  margin-left: 30px !important;
  /* margin:10px 10px !important; */
} 

 .column .is-one-third{
   justify-content: center !important;
     margin: 0 auto !important;
     align-items: center !important;
 }
 .columns.is-centered.login-form{
   display:flex !important;
 }
 .ant-calendar-picker{
   margin-left: 15px !important;
 }
 .couponContainer{
   margin-left: 15px !important;
 }
 .select{
   border: 1px solid transparent !important;
   border-color: #dbdbdb !important;
   border-radius: 4px;
 }
 .couponField{
   width:110px !important;
 }
 .couponPermission{
   width:180px !important;
 }
 .site-page-header{
   margin-bottom:15px !important;
 }
 .btnContainer{
   margin-left:10px !important;
 }

/*---------add admin icons ---------------*/
.admin-icons .ReactTable .rt-tbody .rt-td{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}


/*-------------oppgj�r-------------*/

/*-----------Reports--------------*/
.reportMain-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.reportMain-row .ant-col-4{
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-box{
  width: 190px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 2px #dbdbdb;
  padding: 20px 6px;
  border-radius: 5px;
}
.report-box img{
  max-width:45px;
}


/*-----------------special Permision --------------------*/
.bordered-special{
  display: flex;
  border: 1px solid #dbdbdb;
  padding: 8px 16px;
  border-radius: 5px;
}
.orc-permission .field-body, .field.is-horizontal.orc-permission{
  justify-content: space-between;
}
.bordered-special .field-label{
  margin-right: 0;
}

#dataDisplay .column{
  border: 1px solid #DCDCDC;
}

#mainDataDisplay td:nth-child(even), th:nth-child(even) {
  /* padding: 15px; */
  background-color: #fafafa;
}
#mainDataDisplay td,#mainDataDisplay  th, #machineList td,#machineList th {
  padding: 15px;
  border: 1px solid #dbdbdb;
}

.card-header-icon:hover{
  color:#CCA249 !important;
}
.mainLoginPage{
  background-color: #363636;
}

.mainLoginPage .card{
  border-radius:30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mainLoginPage .card-content {
  padding: 4.5rem 6.5rem;
}

.mainLoginPage .help{
  color: #808080;
}

.button.is-success,.button.is-success:active, .button.is-success.is-active,.button.is-success:hover, .button.is-success.is-hovered,.button.is-primary,.button.is-link,.menu-list a.is-active {
  background-color: #CCA249 !important;
}
.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: none !important;
}
.accStat .ant-select-selection__rendered{
  margin-left: 0;
  margin-right: 34px;
  color: #000;
}
.ant-select-dropdown{
  z-index: 9999 !important;
}


/* new class */
.list-bullets{

  text-align: left;
  margin-left: 100px;
  margin-bottom: 20px;
}
.list-bullets ul{
  text-decoration: dotted;
}